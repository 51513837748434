const BHChangeCountryLanguageTrigger = {
	// country code, language and flag cannot be shown at the same time.
	BHChangeCountryLanguageTrigger: {
		defaultProps: {
			showArrowDown: false,
			showCountryCode: false,
			showCurrencySymbol: false,
			showFlag: false,
			showLanguage: false,
			showSeparator: false,
		},
	},
}
export default BHChangeCountryLanguageTrigger
